export default () => ({
    showEdit: {
        show: false,
        model: 'main'
    },
    userDrawer: {
        results: [],
        next: true,
        count: 0,
        page: 0
    }
})