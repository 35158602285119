<template>
    <a-date-picker
        v-model="date"
        style="width: 100%;"
        :locale="locale"
        :dropdownClassName="dropdownClass"
        :placeholder="placeholder"
        :size="size"
        :format="dateFormat"
        :show-time="showTime"
        :getCalendarContainer="getCalendarContainer"
        :allowClear="allowClear"
        :valueFormat="valueFormat"
        :disabled-date="disabledDate"
        :disabled-time="disabledDateTime"
        :autoFocus="autoFocus"
        @focus="focusHandler"
        @openChange="dateOpenChange"
        @change="dateChange" />
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/ru_RU'
import IMask from 'imask'
export default {
    name: "appComponentsDatepicker",
    props: {
        value: {
            type: [String, Date, Object]
        },
        getCalendarContainer: {
            type: [String, Function],
            default: null
        },
        placeholder: {
            type: [String],
            default: "Выберите дату"
        },
        mask: {
            type: [String],
            default: '00.00.0000 00:00'
        },
        dateFormat: {
            type: String,
            default: 'DD.MM.YYYY HH:mm'
        },
        showTime: {
            type: [Boolean, Object],
            default: false
        },
        allowClear: {
            type: Boolean,
            default: false
        },
        autoFocus: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'default'
        },
        dropdownClassName: {
            type: String,
        },
        dateLimit: {
            type: String,
            default: ''
        },
        valueFormat: {
            type: [Object, String],
            default: null
        },
        focusHandler: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        date:{
            get(){
                return this.value
            },
            set(val){
                if(val)
                    this.$emit('input', val)
                else
                    this.$emit('input', null)
            }
        },
        dropdownClass(){
            return this.dropdownClassName ? this.dropdownClassName : `popup_date_${this.dropRandom}`
        }
    },
    data(){
        return{
            locale,
            dropRandom: Math.floor(Math.random(100,100))
         
        }
    },
    methods: {
        range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },
        disabledDateTime() {
            if(this.dateLimit?.length){
                return {
                    disabledHours: () => this.range(0, this.$moment(this.dateLimit).add(1, 'hours').format('HH'))
                }
            } else
                return false
        },
        disabledDate(current) {
            if(this.dateLimit?.length){
                return current && current < this.$moment(this.dateLimit).subtract(1, 'days').endOf('day')
            } else
                return false
        },
        dateOpenChange(status) {
            this.$nextTick(() => {
                let mask = null
                
                if(status) {
                    this.$nextTick(() => {
                        const input = document.querySelector(`.${this.dropdownClass} .ant-calendar-input `)
                        if(input) {
                            mask = IMask(input, {
                                mask: this.mask
                            })
                        }
                        // input.addEventListener('input', ()=>{
                        //     mask.updateValue();   // update model from view
                       
                        // })
                    })
                } else
                    mask = null
            })
        },
        dateChange(val){
            this.$emit('input', val)
        }
    },
   
}
</script>

<style lang="scss" scoped>
::v-deep .ant-calendar-picker-input.ant-input {
    padding-right: 30px;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>