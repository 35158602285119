<template>
    <div>
        <CreateDrawer />
        <MeetingShowDrawer />
    </div>
</template>

<script>
import store from "./store/index"
import CreateDrawer from './components/CreateDrawer'
import MeetingShowDrawer from './components/MeetingShowDrawer'
export default {
    name: "MeetingInit",
    components: {
        CreateDrawer,
        MeetingShowDrawer
    },
    created() {
        if(!this.$store.hasModule('meeting'))
            this.$store.registerModule("meeting", store)
    }
}
</script>